import styled from 'styled-components'
import { ButtonMenu, ButtonMenuItem ,Flex} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useRouter } from 'next/router'
import Link from 'next/link'

const StyledNav = styled.nav`
  margin-bottom: 0px;
  justify-content: center;
  text-align: center;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    width: 550px;
  }
`

const Wrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-around;
  max-width: 400px;
  height:auto;
  margin-top:2rem;
  margin-bottom:1rem;
  @media screen and (max-width: 600px) {
    padding: 0 20px;    
  }
`

const CustomNavLink = styled.p`
  color: ${({ theme }) => theme.colors.text};
  display: flex ;
  align-items: center ;
  justify-content: center ;
  padding-bottom: 20px ;
  font-family: 'Marcellus';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  width: 100%;
  text-align: center;
`
const WrapLink = styled(Flex)<{isActive:boolean}>`
  width: 150px;
  border-bottom:2px solid ${({ theme, isActive }) => isActive ?theme.colors.text : "none"};
  cursor:pointer;
`
const getActiveIndex = (pathname: string): number => {
  if (
     pathname.includes('/liquidity') || pathname.includes('/add') || pathname.includes('/find') || pathname.includes('/remove')
  ) {
    return 1
  }
  return 0
}

const Nav = () => {
  const { pathname } = useRouter()
  const { t } = useTranslation()
  const activeIndex = getActiveIndex(pathname)
  
  return (
    <Flex width="100%" justifyContent="center">
      <Wrapper>
          <WrapLink isActive={activeIndex === 0 ? !false : false}>
                <Link href="/swap" passHref>
                    <CustomNavLink>
                      {t('Swap')}
                    </CustomNavLink>
                </Link>
              </WrapLink>
              <WrapLink isActive={activeIndex === 1 ? !false : false}>
                <Link href="/liquidity" passHref>
                  <CustomNavLink>
                    {t('Liquidity')}
                  </CustomNavLink>
                </Link>
          </WrapLink>
      </Wrapper>
    </Flex>
  )
}

export default Nav
